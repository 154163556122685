import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  // Common Module

  {
    path: '',
    title: 'Main',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  {
    path: 'home/dashboard',
    title: 'Dashboard',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // {
  //   path: 'home/dashboard/new',
  //   title: 'Dashboard New',
  //   moduleName: 'dashboard',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'home',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [],
  // },

  {
    path: 'home/vendor/performance',
    title: 'Vendor Performance',
    moduleName: 'VendorPerformance',
    iconType: 'material-icons-two-tone',
    icon: 'insights',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  {
    path: 'home/stores',
    title: 'Branch(s) Details',
    moduleName: 'stores',
    iconType: 'material-icons-two-tone',
    icon: 'store',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  {
    path: 'home/orders',
    title: 'Order Management',
    moduleName: 'orders',
    iconType: 'material-icons-two-tone',
    icon: 'receipt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // {
  //   path: 'home/menu',
  //   title: 'Menu Management',
  //   moduleName: 'menu',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'restaurant_menu',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [],
  // },

  {
    path: 'home/storeRatings',
    title: 'Stores Ratings',
    moduleName: 'storeRatings',
    iconType: 'material-icons-two-tone',
    icon: 'hotel_class',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  {
    path: 'home/reports',
    title: 'Reports',
    moduleName: 'reports',
    iconType: 'material-icons-two-tone',
    icon: 'report',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  //  {
  //   path: '',
  //   title: 'Settlement',
  //   moduleName: 'Settlement',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'paid',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [
  //     {
  //       path: 'home/settlement/vendor-settlements',
  //       title: 'Vendor Settlement',
  //       moduleName: 'Settlement',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },

  //     // {
  //     //   path: 'home/Settlement/pending-receipts',
  //     //   title: 'Pending Receipts',
  //     //   moduleName: 'Settlement',
  //     //   iconType: '',
  //     //   icon: '',
  //     //   class: 'ml-menu',
  //     //   groupTitle: false,
  //     //   badge: '',
  //     //   badgeClass: '',
  //     //   role: [''],
  //     //   submenu: [],
  //     // }
  //   ],
  // },
  /*{
    path: 'home/reviews',
    title: 'Reviews',
    moduleName: 'reviews',
    iconType: 'material-icons-two-tone',
    icon: 'rate_review',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
*/
];
